import axios from 'axios';
import {
    API_ROOT,
    logApiError,
} from './api';


const getItems = (path) => {
    return async ({ dispatch, rootGetters }, payload = { context: {}, id: null }) => {
        const userId = rootGetters.user.id;
        const module = `${userId}`;
        const id = payload.id;
        let p = path;
        if (id) {
            p = `${path}${id}/`;
        }
        const url = `${API_ROOT}/${module}/${p}`;
        try {
            const { data } = await axios.get(
                url,
                rootGetters.axiosConfig,
            );
            return data;
        } catch (e) {
            logApiError(e, dispatch);
            throw e;
        }
    };
};

const addOrEditItem = (path, subpath = '', additionalId = '') => {
    return async ({ dispatch, rootGetters }, payload = {}) => {
        const userId = rootGetters.user.id;
        const id = payload.id;
        let method = 'post';
        let url = `${API_ROOT}/${subpath}${userId}${additionalId}/${path}`;
        if (id) {
            method = 'patch';
            url = `${url}${id}/`;
        }
        try {
            const { data } = await axios[method](
                url,
                payload,
                rootGetters.axiosConfig,
            );
            return data;
        } catch (e) {
            logApiError(e, dispatch);
            throw e;
        }
    };
};

export default {
    namespaced: true,
    state: {
        riskAnalysisInfo: {},
    },
    mutations: {
        updateRiskAnalysisInfo(state, riskAnalysisInfo) {
            state.riskAnalysisInfo = riskAnalysisInfo;
        },
    },
    getters: {
        risk_analysis_info: state => {
            return state.riskAnalysisInfo || {};
        },
    },
    actions: {
        getRiskAnalysisInfo: getItems('risk_analysis/'),
        createRiskAnalysisInfo: addOrEditItem('risk_analysis/'),
    },
};
