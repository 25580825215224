import moment from 'moment';
import {STATES_ABBR} from "./constants";

export const intcomma = (value, decimalPrecision = 2, minimumDecimals = 2) => {
    if (value === 0) {
        return '0';
    }
    let val = Number(value);
    if (!Number.isFinite(val)) {
        return '0';
    }
    const parts = val.toString().split('.');
    val = parts[0];
    val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (parts.length === 2) {
        let decimals = parts[1].slice(0, decimalPrecision);
        if (decimals.length < minimumDecimals) {
            decimals += Array((minimumDecimals - decimals.length) + 1).join('0');
        }
        if (decimals) {
            val += `.${decimals}`;
        }
    }
    return val;
};

export const abbreviateNumber = (num, digits = 0) => {
    num = Number(num);
    let abbr = '';
    let rounded = 0;
    if (num >= 1e9) {
        abbr = 'B';
        rounded = num / 1e9;
    }
    else if (num >= 1e6) {
        abbr = 'M';
        rounded = num / 1e6;
    }
    else if (num >= 1e3) {
        abbr = 'K';
        rounded = num / 1e3;
    }
    const test = new RegExp(`\\.\\d{${digits + 1},}$`);
    if (test.test((`${rounded}`))) {
        rounded = rounded.toFixed(digits);
    }
    return rounded + abbr;
};

export const dateFormatter = (val, fmt = 'MMM D, YYYY') => {
    if (!val) {
        return '';
    }
    const date = moment(val);
    return date.format(fmt);
};

export const centToDollarFormatter = (val) => {
    return `$${intcomma(val / 100.0)}`;
};

export const pluralize = (data, singular = '', plural = 's') => {
    if (data === null || data === undefined) {
        return plural;
    }
    if (typeof data === 'number') {
        return data !== 1 ? plural : singular;
    }
    return data.length !== 1 ? plural : singular;
};

export const title = (val) => {
    if (val === undefined || val === null) {
        return '';
    }
    const value = val.toString();
    return value.slice(0, 1).toUpperCase() + value.slice(1);
};

export const mask = (val, showLast = 4, maskChar = '*', ignore = []) => {
    if (!val) {
        return '';
    }
    const chars = [];
    const arrVal = Array.from(String(val));
    arrVal.slice(0, arrVal.length - showLast).forEach(c => {
        if (ignore.indexOf(c) !== -1) {
            chars.push(c);
        } else {
            chars.push(maskChar);
        }
    });
    return chars.concat(arrVal.slice(-showLast)).join('');
};

export const slugify = (val) => {
    const text = `${(val || '').toString()}`;
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};

export const defaultValue = (val, defaultVal = '') => {
    if (val === undefined || val === null) {
        return defaultVal;
    }
    return val;
};

export const stateByAbbr = (abbr, defaultVal = null) => {
    for (const [key, value] of Object.entries(STATES_ABBR)) {
        if (value === abbr) {
            return key;
        }
    }
    return defaultVal;
};

export const stateAbbr = (label) => {
    let is_amt = false;
    const AMT_EXP = / AMT/;
    if (AMT_EXP.test(label)) {
        is_amt = true;
        label = label.replace(AMT_EXP, '');
    }
    label = STATES_ABBR[label] === undefined ? label : STATES_ABBR[label];
    return is_amt ? label + ' AMT' : label;
};

export const accountingNumberFormatter = (value, hideDecimals = false) => {
    let amount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencySign: 'accounting',
    }).format(Number(value));
    if (hideDecimals) {
        const decimals = amount.slice(amount.length - 4, amount.length - 1);
        amount = amount.replace(decimals, '');
    }
    return amount;
};

export const formatSSN = val => {
    let tmp = (val || '').replace(/\D/g, '');
    tmp = tmp.replace(/^(\d{3})/, '$1-');
    tmp = tmp.replace(/-(\d{2})/, '-$1-');
    return tmp.replace(/(\d)-(\d{4}).*/, '$1-$2');
};

export const formatEIN = val => {
    let tmp = (val || '').replace(/\D/g, '');
    tmp = tmp.replace(/^(\d{2})/, '$1-');
    return tmp.replace(/(\d)-(\d{7}).*/, '$1-$2');
};

export const onlyNumbers = (val, includeDecimal = false) => {
    let reg = /[^0-9]*/g;
    if (includeDecimal) {
        reg = /[^.0-9]*/g;
    }
    return (val || '').replace(reg, '');
};

export const noNumbers = (val, includeDecimal = false) => {
    let reg = /[0-9]*/g;
    if (includeDecimal) {
        reg = /[.0-9]*/g;
    }
    return (val || '').replace(reg, '');
};

export const compareLower = (foo, bar) => {
    return String(foo || '').toLowerCase() === String(bar || '').toLowerCase();
};
