import { addOrEditItem, AI_API_ROOT, deleteItem, getItems, logApiError } from './api';
import axios from 'axios';

export default {
    namespaced: true,
    state: {
        conversations: [],
    },
    actions: {
        async getVideoDescription({ dispatch }, payload) {
            try {
                const response = await axios.post(
                    `${AI_API_ROOT}/get_video_description`,
                    payload,
                );
                return response['data'];
            } catch (e) {
                logApiError(e, dispatch);
                return false;
            }
        },

        addConversation: addOrEditItem('conversations/', ''),
        async getConversations({ commit, dispatch, rootGetters }, payload = { context: {}, id: null }) {
            const data = await getItems('conversations/')({ dispatch, rootGetters }, payload);
            if (data) commit('setConversations', data);

            return data;
        },
        deleteConversation: deleteItem('conversations/'),
        addConversationChatMessageItem: addOrEditItem('chat-messages/', 'conversations/'),
    },
    mutations: {
        setConversations(state, conversations) {
            state.conversations = conversations;
        },
    },
};
