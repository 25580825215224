import Vue from 'vue';

import usAllGeo from '@highcharts/map-collection/countries/us/us-all.geo.json';
import Antd from 'ant-design-vue';
import Highcharts from "highcharts";
import HighchartsAnnotations from 'highcharts/modules/annotations';
import HighchartsMapModule from 'highcharts/modules/map';
import HighchartsVue from 'highcharts-vue';
import Rollbar from 'rollbar';
import VueIntercom from 'vue-intercom';
import VueShowdown from 'vue-showdown';

import {
    ROLLBAR_FRONTEND_API_KEY,
    INTERCOM_APP_ID,
    PRODUCTION,
    debug,
    handleWebpackError,
} from './store/constants';
import './filters';
import router from './router';
import store from './store';
import App from './App.vue';

// Import styles
import 'ant-design-vue/dist/antd.less';
import './assets/less/valur_overrides.less';
import './assets/tailwind.css';

Vue.prototype.$rollbar = new Rollbar({
    accessToken: ROLLBAR_FRONTEND_API_KEY,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        // Track your events to a specific version of code for better visibility into version health
        code_version: '1.0.0',
        // Add custom data to your events by adding custom key/value pairs like the one below
        custom_data: 'foo',
    },
});

Vue.config.errorHandler = function (err, vm, info) {
    if (debug) {
        console.log('⚠️ Error!', err, vm, info);
    } else {
        vm.$rollbar.error(err);
    }
    handleWebpackError();
};

if (PRODUCTION) {
    Vue.use(VueIntercom, {appId: INTERCOM_APP_ID});
}

Vue.use(Antd);
Vue.use(VueShowdown, {
    // set default flavor of showdown
    flavor: 'github',
    // set default options of showdown (will override the flavor options)
    options: {
        emoji: false,
    },
});

HighchartsAnnotations(Highcharts);
HighchartsMapModule(Highcharts);
Highcharts.maps['usAllGeo'] = usAllGeo;
require('highcharts/modules/pattern-fill')(Highcharts);
Vue.use(HighchartsVue);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
